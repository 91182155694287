import environment from 'utility/environment'

// Pull these from: https://vocedata-scus-fns02.azurewebsites.net/api/v1/listdata/prod/counties?code=gCg4QiqMua2gL/eKUqKkTLq4vWvRzDlG0izvJgaGzoYDXIe/6Yk0Qg==
const countiesEnabledArray = [
  'Aransas',
  'Atascosa',
  'Bandera',
  'Bastrop',
  'Bell',
  'Bexar',
  'Brazoria',
  'Burnet',
  'Caldwell',
  'Collin',
  'Comal',
  'Dallas',
  'Denton',
  'Ellis',
  'Fort Bend',
  'Galveston',
  'Gillespie',
  'Guadalupe',
  'Harris',
  'Hays',
  'Hunt',
  'Kaufman',
  'Kendall',
  'Kerr',
  'Lubbock',
  'McLennan',
  'Medina',
  'Montgomery',
  'Nueces',
  'Rockwall',
  'San Patricio',
  'Tarrant',
  'Travis',
  'Williamson',
]

const CountiesEnabledString = countiesEnabledArray
  .sort((a, b) => b - a)
  .join(', ')
// const CountiesEnabledString = 'Bexar, Collin, Comal, Dallas, Guadalupe, Hays, Kendall, Tarrant, Travis, Williamson'

export const centerOfTexas = { lat: 31.3915, lng: -99.1707 }
export const mapCenter = { lat: 29.9915, lng: -99.1707 }

// On homepage, the date just goes away (Dont show it on the homepage)
export const GlobalDeadlineDate = new Date(2024, 4, 15, 19, 0, 0)

export const StripeToken = environment.production
  ? 'pk_live_bVCX5JBmbAAZfwUi8sMw3Uor00pICI46ju'
  : environment.review
    ? 'pk_test_Kzj2uAp9GYgKHvIstBt9HMid000BTRUbpX'
    : 'pk_test_51OZMelI3jhgP8xlNSRjqxZViNCALTlZ9tj7UfnM6fdn01F9XhxeE0Nd43l6524eYGthLF5uDtrsQLWaFyqKKZNT200R928gvuz'

export const cancelList = [
  'Sold the property',
  "Don't want a subscription",
  'Unhappy with results',
  'Prefer to DIY protest',
]

export default {
  CountiesEnabledString,
  centerOfTexas,
  mapCenter,
  GlobalDeadlineDate,
}