import React from 'react'
import styled from 'styled-components'
import colors from 'utility/colors'

const StyledExternalLink = styled.a`
  color: ${colors.GREEN};
  display: block;
  padding-bottom: 5px;
  font-size: 16px;
  :hover {
    color: ${colors.GREEN};
  }
`

const StyledDiv = styled.div`
  color: ${colors.GREEN};
  display: block;
  padding-bottom: 5px;
  font-size: 16px;
  :hover {
    color: ${colors.GREEN};
  }
`

const ExternalLink = (props) => {
  if (!props.href) return <StyledDiv {...props} />

  return <StyledExternalLink {...props} />
}

export default ExternalLink
