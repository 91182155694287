import React, { useState, useEffect } from 'react'
import { Container, Col } from 'react-bootstrap'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

import Page from 'components/common/Page'
import Hero from 'components/common/Hero'
import Link from 'components/common/Link'
import Text from 'components/common/Text'
import Title from 'components/common/Title'

const AreaDeliversBlock = dynamic(() =>
  import('components/common/blocks/AreaDeliversBlockAlt'),
)
const WhyChooseHTSBlock = dynamic(() =>
  import('components/common/blocks/WhyChooseHTSBlock'),
)
const ReadyToSignupBlock = dynamic(() =>
  import('components/common/blocks/ReadyToSignupBlock'),
)
const AreaSavingsBlock = dynamic(() =>
  import('components/common/blocks/AreaSavingsBlock'),
)
const DontDreadBlock = dynamic(() =>
  import('components/common/blocks/DontDreadBlock'),
)
const ProtestingIsWorthItBlock = dynamic(() =>
  import('components/common/blocks/ProtestingIsWorthItBlock'),
)

const Map = dynamic(() => import('components/common/Map'))
const PropertyForm = dynamic(() =>
  import('components/common/forms/PropertyForm'),
)

import { useApp } from 'components/providers/AppProvider'
import * as stateQuery from 'utility/queries/state'

import { toSlug } from 'utility/string'
import { moneyWithComma } from 'utility/numbers'
import colors from 'utility/colors'
import getNested from 'utility/getNested'
import HomesBackground from 'assets/backgrounds/Homes.jpg'
import TexasImage from 'assets/inline/texas.png'
import { centerOfTexas } from 'utility/constants'
import { useSelf } from 'components/providers/SelfProvider'

let HomePage = ({ stateData }) => {
  const router = useRouter()
  const { isSignedIn, user } = useSelf()
  const [map, setMap] = useState(null)

  const { breakpoints } = useApp()

  useEffect(() => {
    if (!map || !breakpoints) return null

    if (breakpoints.lg) {
      map.setCenter(centerOfTexas)
      map.panBy(-280, -130)
    } else {
      map.setCenter(centerOfTexas)
    }
  }, [map, breakpoints])

  let title = `Helping homeowners pay fair property taxes in Texas - Home Tax Shield`

  const statsClaimed = moneyWithComma(
    getNested(stateData, 'savingsStats', 'savingsClaimed'),
  )
  const statsUnclaimed = moneyWithComma(
    getNested(stateData, 'savingsStats', 'savingsUnclaimed'),
  )
  const statsPct = getNested(stateData, 'savingsStats', 'avgTaxReductionPct')

  if (isSignedIn) {
    router.replace(user?.baseSlug)
  }

  return (
    <Page
      title={title}
      overlayColor={colors.BLUE}
      description={
        'Welcome to Home Tax Shield - let us help you get and pay fair property taxes in Texas!'
      }
      appendTitle={false}
      footer
      footerBackground={colors.WHITE}
      fluid
    >
      <Hero
        withLogo
        title={
          <>
            Why Pay a Dollar More Than You Have to in{' '}
            <Title
              size={breakpoints.md ? 40 : 32}
              style={{ display: 'inline-block' }}
            >
              <Title
                size={breakpoints.md ? 40 : 32}
                style={{
                  fontWeight: 'bold',
                  display: 'inline',
                  backgroundColor: `${colors.ACCENT}AA`,
                  borderRadius: 5,
                  paddingLeft: 3,
                  paddingRight: 3,
                  marginLeft: -3,
                }}
              >
                Property Taxes
              </Title>
              ?
            </Title>
          </>
        }
        subtitle={<>Protesting is easy. Because we do it for you.</>}
        titleSize={breakpoints.md ? 40 : 32}
        titleStyle={{ textAlign: breakpoints.sm ? 'left' : 'center' }}
        height={'20vh'}
        opacity={0.2}
        mdOffset={0}
        md={12}
        lgOffset={2}
        lg={8}
        backgroundImage={HomesBackground}
        body={
          <>
            <Col
              xs={{ span: 12, order: 'last' }}
              md={{ span: 12 }}
              lg={{ span: 8, offset: 2 }}
              style={{ paddingBottom: 50, marginTop: 40 }}
            >
              {/* <Text align={'left'} size={breakpoints.md ? 24 : 20} color={colors.WHITE} style={{marginBottom: 10}}>
                See how much you can save!
              </Text> */}
              <PropertyForm
                withLabels={false}
                promptText={'Enter your home address to start...'}
                height={60}
                inputStyle={{
                  padding: 20,
                  fontSize: 18,
                }}
                onAdd={(property) => {
                  const slugAddress = toSlug(
                    getNested(property, 'propertyAddress'),
                  )
                  let state = 'texas'
                  router.push(
                    `/${state}/property/${
                      slugAddress ? slugAddress : 'property'
                    }/${getNested(property, 'searchId')}`,
                  )
                }}
              />
              <Text
                size={16}
                color={colors.WHITE}
                style={{ marginTop: 8, textAlign: 'left' }}
              >
                Serving{' '}
                <Link to="/coverage">
                  <a
                    style={{ color: colors.WHITE, fontWeight: 'bold' }}
                    target={'_blank'}
                  >
                    <u>these Texas counties</u>
                  </a>
                </Link>
              </Text>
            </Col>
          </>
        }
      />

      <div style={{ marginTop: 60 }}>
        <DontDreadBlock />
      </div>

      <div style={{ position: 'relative' }}>
        <Container>
          <AreaDeliversBlock />
        </Container>
      </div>

      <div style={{ position: 'relative', marginTop: 50 }}>
        <ProtestingIsWorthItBlock />
      </div>

      <div style={{ position: 'relative' }}>
        <Map
          zoom={5}
          onReady={(mapProps, _map, _google) => {
            setMap(_map)
          }}
        />
        <div
          style={{
            position: 'absolute',
            zIndex: 9,
            top: 0,
            right: 0,
            left: 0,
            width: '100%',
            height: '50%',
            background: `linear-gradient(${colors.WHITE}, ${colors.WHITE}00)`,
          }}
        />

        <div
          style={{
            position: 'absolute',
            zIndex: 9,
            bottom: 0,
            right: 0,
            left: 0,
            width: '100%',
            height: '20%',
            background: `linear-gradient(${colors.WHITE}00, ${colors.WHITE})`,
          }}
        />

        <Container style={{ position: 'relative', zIndex: 99 }}>
          <AreaSavingsBlock
            polyCoords={true}
            primaryImageUrl={TexasImage}
            statsUnclaimed={statsUnclaimed}
            statsClaimed={statsClaimed}
            statsPct={statsPct}
          />
        </Container>
      </div>
      <div style={{ backgroundColor: colors.WHITE, marginTop: 60 }}>
        <WhyChooseHTSBlock />
      </div>
      <div style={{ marginTop: 60, marginBottom: 60 }}>
        <ReadyToSignupBlock
          link={`/signup`}
          titleContainerStyle={{ marginTop: 0, marginBottom: 40 }}
          containerStyle={{ marginTop: 50, marginBottom: 50 }}
        />
      </div>
    </Page>
  )
}

export async function getStaticProps({ query }) {
  const stateData = await stateQuery.fetchState({ state: 'texas' })
  return {
    props: {
      stateData: stateData ? stateData : null,
    },
    revalidate: 60 * 60, // 60 min refresh rate
  }
}

export default HomePage
